@import '~antd/dist/antd.less';
@import './theme/colorsAndConstants.less';
@import './theme/fonts.less';
@import './antd-overrides.less';

// This file must only contain styles common to the whole project or overrides

@font-face {
  font-family: 'Corbel';
  src: url('./theme/Corbel.ttf');
}

body {
  font-family: 'Inter';
  background-color: @light-background-color;
}

.App {
  position: relative;
}

.setting-container .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.payment-feature {
  margin: 0.5rem 0;
}

.question-page .ant-radio-group {
  display: flex;
  flex-direction: column;
}

.question-page .ant-checkbox-wrapper {
  margin-right: 8px;
  padding: 0.8rem;
  border-bottom: 1px solid #e5ebf0;
  font-size: 15px;
}

.question-page .ant-checkbox-wrapper:first-child {
  margin-left: 0.6rem;
}

.horizontal-line {
  width: 100%;
  border: 1px solid #e1edff;
  margin: 0.5rem;
}

.setting-container .ant-checkbox-wrapper:first-child {
  margin-left: 0.55rem;
}

.setting-container .ant-checkbox-group-item {
  margin-left: 6px;
  margin-right: 8px;
  padding: 0.6rem;
  border-bottom: 1px solid #e5ebf0;
  font-size: 15px;
}

.companyName:hover {
  text-decoration: underline;
}

.JobCard {
  background-color: white;
  border: 2px solid @border-color;
  border-radius: @border-radius;
  width: 30rem;
  height: 13rem;
  padding: 1rem;
  overflow: auto;
  text-align: center;
  overflow-wrap: break-word;
}

.jobHeader:hover {
  cursor: pointer;
}

.jobHeader {
  padding-bottom: 1rem;
}

.RemoteJobCard {
  border: 2px solid @border-color;
  border-radius: @border-radius;
  width: 100%;
  padding: 1rem 1.5rem;
}

.dashboard {
  width: 80%;
  margin: auto;
}

.jobsSection {
  gap: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-background {
  background: white;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  font-weight: 500;
}

// Buttonss -----------------------

.upgradePremiumButton {
  background: @secondary-button-color !important;
  border: 1px solid @secondary-button-color;
  color: white !important;
  border: @secondary-button-color !important;
}

.upgradePremiumButton:hover {
  color: white !important;
  background: #4280DC !important;
}

.upgradePremiumButton:active {
  color: white !important;
  background: #4280DC !important;
}

.upgradePremiumButton:focus {
  color: white !important;
  background: #4280DC !important;
}

.temp .ant-modal-content {
  background: #e1edff;
}

.ant-modal-content {
  font-family: 'Inter';
}

.premiumButton {
  background: #24447e;
  border-radius: 2rem;
  padding: 1rem;
  height: 1.5rem;
  color: white !important;
  display: flex;
  align-items: center;
  border: 1px solid #445880;
  align-self: center;
}

.primary-btn {
  border-radius: 4px !important;
  background: @secondary-button-color !important;
  color: white !important;
}

.tertiary-btn {
  color: @secondary-button-color !important;
  background-color: @background-light-color !important;
  border-radius: 4px !important;
}

.createBtn {
  background-color: @secondary-button-color !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 12px 20px !important;
  height: 2.5rem !important;
  border-color: @secondary-button-color !important;
}

.createBtn:hover {
  background-color: #417bd1 !important;
}

.resetBtn {
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 12px 20px !important;
  height: 2.5rem !important;
}

.discard {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  gap: 6px !important;
  background: #e1edff !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 2.5rem !important;
  color: var(--secondary-button-color, #1570ef) !important;
}

.add-btn {
  border-radius: 4px !important;
  border: 1px solid var(--secondary-button-color, #1570ef) !important;
  background: var(--F8FBFE, #edf3fd) !important;
  color: var(--secondary-button-color, #1570ef) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.orange-btn {
  background: @secondary-color !important;
  border: @secondary-color !important;
}

.orange-btn:hover {
  background: @secondary-light-color !important;
  border: @secondary-light-color !important;
}

.orange-btn:active {
  background: @secondary-heavy-color !important;
  border: @secondary-heavy-color !important;
}

.secondary-button {
  background-color: @light-background-color !important;
  color: @secondary-button-color !important;
  border: 1px solid @secondary-button-color !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 2.5rem !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  gap: 6px !important;
}

.link-btn {
  box-shadow: none !important;
  color: @secondary-button-color !important;
  border: none !important;
  cursor: pointer;
}

.link-btn:hover {
  color: #4280dc !important;
  scale: 1.05;

}

.secondary-button:hover {
  color: @secondary-button-color !important;
  scale: 1.05;
}

// -----------Buttons /----------

// -------------------------  antd overrides -----------------------------------

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.menu-root.ant-menu-inline {
  box-shadow: none;
  padding-right: 0.8rem;
}

.ant-layout-header {
  height: 64px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.banner .ant-alert-message {
  color: white;
}

.banner .ant-alert-close-icon .anticon-close {
  color: white;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  background: @background-light-color;
  border-radius: 5px;
  border-right: 0px;
  padding-right: 0.8rem;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
  margin: auto;
}

.ant-card-body {
  padding: 10px !important;
}

.jobDetail .ant-modal-body {
  padding: 0;
}

.ant-modal-body {
  padding: 0 0;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
  margin-bottom: 0;
  padding: 0 20px;
  background-color: #fbfcfc;
  font-size: 12px;
}

.ant-menu-item-selected {
  background-color: @background-light-color !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
}

.jobdetail .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 0px;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
  border-left: 4px solid @secondary-button-color;
}

.ant-layout-header {
  height: 48px;
}

.ant-select {
  min-width: 7rem;
}

.ant-modal-mask {
  background-color: #152c5b;
  opacity: 0.8;
}

.zero-job-button .ant-btn {
  border: 1px solid #152c5b;
}

.zero-job-button .section-container .ant-card-bordered {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.section-container .ant-card-body {
  padding: 15px 15px;
}

.section-container .ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: none;
  display: inline;
  transform-origin: none;
}

.section-container .ant-badge-count {
  box-shadow: none;
}

.question-first-page .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
}

.question-first-page .ant-select {
  width: 100%;
  margin-bottom: 1rem;
}

.question-page .ant-radio-wrapper {
  margin: 6px;
  font-size: 15px;
}

.question-page .ant-checkbox-group {
  display: flex;
}

.job-card .ant-card-bordered {
  border-radius: 10px;
  box-shadow: rgb(99 99 99 / 20%) 0px 1px 0px 0px;
}

.ant-checkbox+span {
  padding-right: 8px;
  padding-left: 20px;
}

.ant-layout-sider-children .ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
  border-left: 4px solid @secondary-button-color;
  border-radius: 5px;
}

.ant-menu-submenu-popup ul {
  left: -4rem;
  position: absolute;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
}

.ant-modal-header {
  border-radius: 8px;
}

.create_job_modal .ant-modal-title {
  width: 30%;
  margin-left: 40%;
  color: #152c5b;
  font-size: 20px;
  font-family: 'Inter';
}

.create_job_modal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 11px;
  font-size: 16px;
  height: 41px;
}

.ant-layout-header {
  background-color: #fff;
}

.ant-picker-panel-container {
  font-family: 'Inter';
}

.banner .ant-alert-message {
  text-align: right;
}

.banner .ant-alert-close-icon {
  font-size: 14px;
  padding-right: 1rem;
}

.banner .ant-alert-action {
  margin-right: 30%;
}

.inputDefault {
  background: #ffffff;
  height: 2.4rem;
  border-radius: 3px;
  width: 94%;
  margin: auto;
}

.noData {
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  height: 78vh;
  top: 70px;
  position: absolute;
  text-align: center;
  padding-top: 12%;
}

.noData-heading {
  width: 60%;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #152c5b;
  margin: auto;
  margin-top: 1.5rem;
}

// --------------------------- Scroll or webkit overrides -----------------------

::-webkit-scrollbar {
  background: white;
  scrollbar-width: none;
}

/* Style the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #c0cfea;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.8em;
}



.sectionResume ::-webkit-scrollbar {
  width: 0.7em !important;
  height: 0.9em !important;
}

.JobTracker_add_job_form__q8o6d .ant-form-item-label>label {
  font-family: 'Inter';
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 18px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 600;
}

.card {
  background: white;
  box-shadow: 0px 4px 4px rgba(80, 141, 232, 0.2);
  border-radius: 5px;
  padding: 1rem;
}

.contact .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 3px;
  border: 1px solid #e5ebf0;
  background: var(--fbfcfc, #fbfcfc);
  height: 2.6rem;
  padding: 5px 12px;
}

.contact .ant-select-arrow {
  top: 60%;
}

.contact .ant-table-thead>tr>th {
  background-color: white;
  color: var(--Gray-500, #667085);
}

.contact .ant-table-thead>tr>td {
  background-color: white;
  color: var(--Gray-500, #667085);
}

.resumeListTable tr>th {
  font-size: 13px;
  font-family: Inter;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.resumeListTable tr>td {
  font-size: 13px;
  font-family: Inter
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: #4285f4 !important;
}

.ant-spin-item {
  background: #4285f4 !important;
}

.ant-spin-text {
  color: #4285f4 !important;
}

.nopadding .ant-modal-body {
  padding: 1rem 0rem 0rem 0rem;
}

.payment-card {
  border: 1px solid #e1edff;
  border-radius: 4px;
  background-color: white;
}

div.ant-modal-root:has(#payment-modal) .ant-modal-content {
  border-radius: 2rem;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-close-x {
  line-height: 49px;
}

.selected-payment {
  background-color: @background-light-color;
}

div.ant-modal-root:has(#payment-modal) .ant-modal-close-x {
  color: white;
}

.dottedBg {
  height: 100%;
  background-image: radial-gradient(#ccc 9%, transparent 11%);
  background-size: 15px 15px;
  background-position: 0 0, 30px 30px;
  background-repeat: repeat;
  border-radius: 8px;
  background-color: #e1edff;
}

.flex-center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.advancedOptionsCollapse .ant-collapse-header {
  padding: 0 !important;
}

.advancedOptionsCollapse .ant-collapse-content-box {
  padding: 0 !important;
}

.payment-modal {
  padding: 0;
  border-radius: 8px;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.createJobModal .ant-modal-content {
  height: 100% !important;
  overflow: auto;
}

.ant-dropdown-menu {
  border-radius: 8px !important;
}

.ant-dropdown-menu-item:hover {
  background-color: #f0f6ff !important;
}

// --------------------------- Chat UI overrides -----------------------

.cs-message--outgoing,
.cs-message--incoming {
  border-width: 1px !important;
  border-color: #e1edff !important;
}

.cs-message--outgoing .cs-message__content-wrapper * {
  background-color: #f0f6ff !important;
  border-top-right-radius: 8px !important;
}

.cs-message--incoming .cs-message__content-wrapper * {
  background-color: @light-background-color !important;
  border-bottom-left-radius: 8px !important;
}

.cs-message--outgoing .cs-message__content-wrapper,
.cs-message--incoming .cs-message__content-wrapper {
  padding: 10px 12px 10px 12px;
}

.onboarding-form-page {
  height: 80vh;
  padding: 2.5rem;
  overflow: auto;
}

.graphCard {
  border: 1px solid #e1edff;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: #ffffff;
  width: 100%;
}

#toolbar {
  display: none !important;
}

.discardBtn {
  border-radius: 4px !important;
  border: 1px solid var(--secondary-button-color, #1570ef) !important;
  background: #fff !important;
  height: 2.4rem !important;
  color: var(--secondary-button-color, #1570ef) !important;
}

.rpv-core__inner-page {
  background-color: #e5ebf0 !important;
}

.rpv-core__inner-pages {
  background-color: #e5ebf0 !important;
}

.ant-table-thead>tr>th {
  background-color: white;
  color: var(--Gray-500, #667085);
  font-weight: 500;
}

.ant-table-thead>tr>td {
  background-color: white;
  color: var(--Gray-500, #667085);
}

.viewDoc {
  font-family: 'Inter';
}

.viewDoc .ant-modal-close-x {
  line-height: 48px;
}

.svgDownload {
  fill: #96a0b5;
}

.svgDownload:hover {
  fill: @secondary-button-color;
}

.resumeSvg {
  fill: #1d2939;
}

.resumeSvg:hover {
  fill: @secondary-button-color;
}

.resumeIconWrapper:hover .resumeSvg {
  fill: @secondary-button-color;
}

.resumeIconWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  contain: content;
  padding: 10px 16px;
  justify-content: center;
  border-right: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  border-left: 1px solid #fff;
}

.modalHeading {
  padding: 10px 12px 0.5rem 24px;
  font-weight: 500;
  font-size: 18px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.createResumeModal {
  border-radius: 10px;
  font-family: 'Inter';
}

.createResumeModal .ant-modal-close-x {
  line-height: 56px;
}

.resume-secondryBtn {
  border-radius: 5px !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  background: var(--White, #fff) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  display: flex !important;
  padding: 10px 18px;
  justify-content: center !important;
  align-items: center !important;
  color: var(--Gray-700, #344054) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: 'Inter';
  height: 2.4rem !important;
}

.resumeSections .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px !important;
  border-radius: 5px !important;
  padding: 5px 10px;
}

.formInput {
  display: flex !important;
  padding: 10px 14px !important;
  align-items: center !important;
  gap: 8px !important;
  align-self: stretch !important;
  border-radius: 5px !important;
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  background: var(--White, #fff) !important;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  margin-bottom: 1rem !important;
}

.btnContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.formContainer {
  margin-top: 1.2rem;
  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}

.formContainer:focus-within {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: 1px solid var(--Brand-400, #7daaee);
  border-radius: 5px;
}

.modal-mount .ant-modal-mask,
.modal-mount .ant-modal-wrap {
  position: absolute !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  display: flex !important;
  align-items: center !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  display: flex !important;
  align-items: center;
}

.resumelabel {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 14px;
  font-style: 'Inter';
  font-weight: 500;
  margin-bottom: 6px;
}

.resumelabelName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.resumelabelicon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.resumeSections .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0 0 2px 2px;
  padding: 0;
}

.resumeSections .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 55%;
  right: -10px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}

.resumeSections .tox-tinymce {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.resumeSections .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.resumeSections .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
  display: flex !important;
  align-items: center !important;
  height: 2.4rem;
}

#tinymce .mce-content-body {
  font-size: 14px !important;
}

.resume-page {
  font-size: 11pt;
  width: 8.5in;
  min-height: 11.7in;
  height: auto; //`11.7in`,
  background-color: white;
  color: black;
}

#resumeContent2 .resume-page {
  height: 11.7in;
  margin-bottom: 2rem;
}

.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  max-width: 35rem !important;
}

.ant-popover-title {
  padding: 12px 16px 12px;
}

.resumeSkill .ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding: 12px 0 !important;
}

.anticon svg {
  vertical-align: initial;
}

#modalMountRight .ant-modal-mask {
  position: absolute;
}

#modalMountRight .ant-modal-wrap {
  position: absolute;
}

.ant-btn-primary {
  background: @secondary-button-color !important;
}

.ant-btn-dangerous.ant-btn-primary {
  background: #ff4d4f !important;
}

.inputNotVisible {
  background-color: #f2f4f7 !important;
  border-radius: 4px !important;
}

.inputNotVisible .ant-select-selector {
  border-radius: 4px !important;
  background-color: #f2f4f7 !important;
}

.ant-tooltip-open>svg {
  cursor: pointer;
  color: #81848b // TODO: Change this. Currently it is random shade of gray color chosen by Puneet 
}

.ant-layout-sider-children .ant-menu-title-content>span {
  display: flex;
}

html {
  scroll-behavior: smooth !important;
}

.resumeAnalysisDiv .ant-radio-button-wrapper-checked {
  border-color: none !important;
}


.formWarning {
  border-radius: 5px !important;
  border: 1px solid var(--Error-300, #FDA29B) !important;
  background: var(--White, #FFF) !important;

  /* Shadow/xs focused 4px error-100 */
  box-shadow: 0px 0px 0px 4px #FEE4E2, 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

}

.strongMatchDiv .ant-table-thead>tr>th {
  background-color: #ECFDF3 !important;
}

.missingMatchDiv .ant-table-thead>tr>th {
  background-color: #ffebeb !important;
}

.partialMatchDiv .ant-table-thead>tr>th {
  background-color: #FFFAEB !important;
}

.aiPopup {
  fill: #667085
}

.aiPopup:hover {
  fill: @secondary-button-color;
}

.cfAIShiningBtn {
  color: white;
  border-radius: 4px;
  border: 1px solid #508DE8;
  padding: 6px 14px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
  background: linear-gradient(88.86deg, rgb(52, 123, 246), rgb(51, 141, 250), rgb(50, 160, 255), rgb(51, 141, 250), rgb(52, 123, 246));
  background-size: 200% 200%;
}

@keyframes hello {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.cfAIShiningBtn:hover {
  animation: hello 0.8s ease-in-out both;
}

.cardBordered {
  border: 1px solid #508DE8;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 12px 16px -4px #10182814;
  border-radius: 0.5rem;
}

.tag-ai-feature {
  padding: 4px 8px;
  gap: 2px;
  font-size: 10px;
  background-color: @background-light-color;
  color: @dark-text-color;
  border-radius: 1rem;
  display: inline-flex;
  align-items: center;
}

.resume-page-break-div {
  height: 11.7in;
  border-bottom: 2px dashed #A9A9A9;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 110%;
  right: 5%;
  font-size: 16px;
  color: gray;
  font-weight: 500;
}

.sectionResume a {
  color: rgb(37, 99, 235) !important;
}

@font-face {
  font-family: Calibri;
  src: url("./theme/Calibri.ttf");
}

img {
  object-fit: contain;
}

.quill>* {
  border: none !important;
}


.ql-toolbar {
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ql-container {
  border: 1px solid var(--Gray-300, #d0d5dd) !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-btn-dangerous {
  color: @dangerous-color !important;
  border-color: @dangerous-color !important;
}

a {
  color: @secondary-button-color;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: @secondary-button-color !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: @secondary-button-color !important;
}

.ant-radio-button-wrapper:hover {
  color: @secondary-button-color !important;
}


.saveBtn {
  background: @secondary-button-color !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
  color: #fff !important;
  padding: 0.5rem 1rem !important;
  margin: 0 0.2rem 0rem 1rem !important;
  height: 2.4rem;
}

.saveBtn:hover {
  background: #1a61cd !important;
}

.active-button {
  color: white;
  background-color: #e0edff !important;
  border-color: #1890ff !important;
}

.interactive-icon:hover {
  transform: scale(1.2);
}

.ant-btn-link {
  color: @secondary-button-color !important;
}
@primary-color: #1570EF;@primary-color-active: #1570EF;@text-color: #152C5B;@font-family: Lexend, sans-serif;